<template>
  <v-container fluid>
    <v-btn class="back-button" elevation="0" @click="goToMarketing" text>
      <SvgIcon text="Back">
        <template v-slot:icon>
          <BackIcon/>
        </template>
      </SvgIcon>
    </v-btn>

    <v-container  fluid style="max-width: 80%" >
      <v-form @submit.prevent="handleFormSubmit">
        <v-row justify="center">
          <v-col cols="12" >
            <div style="height: 225px; width: 600px; margin: auto">
              <image-uploader
                  bg_color="white"
                  messagePosition="inside"
                  @upload="
                      (data) => {
                        template.header_image = data;
                      }
                "
                  @remove="
                  () => {
                    template.header_image= null;
                  }
                "
                  :image_path="typeof template.header_image === 'string'?template.header_image:null"
              />
            </div>
          </v-col>
        </v-row>

        <v-row dense class="mt-12">
          <div class="col-sm-12 col-md-12 ">
            <p  class="text-base mb-2 font-bold">Email Subject(*)</p>
            <v-text-field
                v-model="template.subject"
                :rules="[(v) => !!v || 'Subject is required']"
                outlined
                required
                elevation="0"
                style="background-color: #FFFFFF"
                class="q-text-field shadow-0"
                :dense="true"
                hide-details="auto"
            />
          </div>
        </v-row>

        <v-row dense class="mt-6">
          <div class="col-12">
            <p  class="text-base mb-2 font-bold"> Body text(*)</p>
            <span class="overline">Placeholders</span>
            <v-tooltip max-width="250" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </template>
              <span class="text-center">
                  Placeholder is used to add dynamic data in you message.
                  Placeholder will be replaced with actual data representing
                  each of them. Please click to copy and paste it on your
                  message
                </span>
            </v-tooltip>
              <v-chip
                  v-for="item in placeholders"
                  :key="item.id"
                  class="mr-2 pa-4"
                  @click="copyPlaceholder(item)"
              >
                {{ item.name }}
                <v-icon right small>mdi-content-copy</v-icon>
              </v-chip>
          </div>
          <v-col cols="12" md="12" sm="12">
<!--            <text-editor-->
<!--                @complete="setEmailContent"-->
<!--                :message="template.content"-->
<!--            />-->
            <RichEditor
                v-model="template.content"
                id="editor"
            />
          </v-col>
        </v-row>


        <v-row dense class="mt-6">
          <div class="col-12">
            <p  class="text-base mb-3 font-bold">Footer Images</p>
          </div>

          <v-col cols="12">
            <div class="d-flex gap-x-5 ">
              <div  style="height: 225px; width: 281px" v-for="(num, index) in 3" :key="index">
                <image-uploader
                    bg_color="white"
                    messagePosition="inside"
                    @upload="
                  (data) => {
                    template.footer_images[index] = data;
                  }
                "
                    @remove="
                  () => {
                    template.footer_images[index] = null;

                  }
                "
                    @deleteImg="deleteImg"
                    :image_path="template.footer_images[index]"
                    :imgIndex="index"
                    :delete-uploaded-image="true"
                />

              </div>
            </div>
          </v-col>

        </v-row>


        <v-row dense class="mt-6">
          <div class="col-12 ">
            <v-expansion-panels accordion flat >
              <v-expansion-panel @change="pannelTransitionChanged" >
                <v-expansion-panel-header style="padding: 0" expand-icon="">
                  <SvgIcon text="Add Privacy Policy(*)" class="font-semibold text-base font-16 p-l-3">
                    <template v-slot:icon>
                      <PlusIcon v-if="!isPanelOpened"/>
                      <MinusIcon v-if="isPanelOpened"/>
                    </template>
                  </SvgIcon>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <div class="col-12 px-6">
                     <RichEditor
                        v-model="template.policy"
                        id="editor"
                    />
                    </div>
                  </v-row>
                </v-expansion-panel-content>

              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-row>


        <v-row class="mt-12">
          <v-col cols="6">
            <v-btn
                color="teal-color"
                dark
                class="shadow-0"
                @click="saveTemplate(null,1)"

            >
              Preview
            </v-btn>

          </v-col>
          <v-col cols="6 text-right">
            <v-btn
                class="ma-2 black--text shadow-0 text-capitalize" style="background-color: rgba(17, 42, 70, 0.1)"
                color="darken-1"
                text
                right
                @click="saveTemplate(2)"
                v-if="checkWritePermission($modules.marketing[getTemplateType(template.slug)].slug)"
            >Save  Draft
            </v-btn>
            <v-btn
                class="ma-2 white--text blue-color shadow-0"
                color="darken-1"
                text
                right
                @click="saveTemplate(1)"
                v-if="checkWritePermission($modules.marketing[getTemplateType(template.slug)].slug)"

            >Active
            </v-btn>
          </v-col>
        </v-row>


      </v-form>
    </v-container>
    <EmailPreviewModal :dialoge="previewModal" :templateId="template.id" v-if="previewModal" :previewContent="previewContent" @closePreviewModal="closePreviewModal"/>
  </v-container>

</template>

<style scoped>

</style>
<script>

import PlusIcon from "@/assets/images/misc/plus.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import MinusIcon from "@/assets/images/misc/minus-icon.svg";
import BackIcon from "@/assets/images/misc/back-icon.svg";
import EmailPreviewModal from "@/components/Marketing/EmailPreviewModal.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import RichEditor from "@/components/Common/RichEditor.vue";

export default {
  name: "ConfigureEmailTemplate",
  components: {RichEditor, ImageUploader, EmailPreviewModal, BackIcon, MinusIcon,  SvgIcon, PlusIcon, },
  data(){
    return{
      isPanelOpened:false,
      previewModal :false,
      placeholders:[],
      previewContent:'',
      template :{
        id:0,
        header_image:null,
        subject:null,
        content:'',
        footer_images:[],
        policy:null,
        slug:this.$route.params.slug,
        deletedFooterImages:[],
      },
    }
  },

  mounted() {
    if(this.$route.params.id){
      this.getMessageDetails(this.$route.params.slug, this.$route.params.id);
    }
    this.getPlaceholders();

  },



  methods:{
    deleteImg(imgIndex){
      this.template.deletedFooterImages.push(this.template.footer_images[imgIndex])
      this.template.footer_images.splice(imgIndex,1)
    },
    goToMarketing() {
      this.$router.push({name: "Marketing"}, () => {
      });
    },
    pannelTransitionChanged(){
      this.isPanelOpened = !this.isPanelOpened
    },
    setEmailContent(content) {
      this.template.content = content;
    },
    setPolicyContent(content) {
      this.template.policy = content;
    },


    copyPlaceholder(data) {
      const el = document.createElement("textarea");
      el.value = data.placeholder;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
          document.getSelection().rangeCount > 0
              ? document.getSelection().getRangeAt(0)
              : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showInfo(
          "Placeholder for " + data.name + " copied to clipboard",
          2000
      );
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },

    getMessageDetails(slug, id = null) {
      this.showLoader("Loading");
      let url = `venues/marketing/get-template/${slug}`;
      if(id){
        url+=`/${id}`;
      }
      this.$http
          .get(url)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data.email;
              this.template = data;
              this.template.deletedFooterImages = [];
              this.hideLoader();
              this.$forceUpdate()
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },


    uploadImg(img){
      console.log(img)
    },

    getPlaceholders() {
      let url = 'venues/marketing/messages/placeholders';
      if(this.$route.params.slug === 'pending-booking-mail' || this.$route.params.slug==='reminder-mail'){
        url+='?type=facility_confirmation';
      }else if(this.$route.params.slug === 'promo-code-mail' ){
        url+='?type=promo_mail';
      }else if(this.$route.params.slug === 'order-reservation-mail' ){
        url+='?type=order_reservation';
      }else if(this.$route.params.slug === 'order-refund-mail' ){
        url+='?type=order_refund';
      }else if(this.$route.params.slug === 'order-confirmation-mail' || this.$route.params.slug === 'order-cancellation-mail' ){
        url+='?type=order_confirmation';
      }else if(this.$route.params.slug === 'membership-purchase-mail' ){
        url+='?type=membership_purchase';
      }else if(this.$route.params.slug === 'event-cancellation-mail' ) {
        url += '?type=event_cancel';
      }
      this.$http
          .get(url)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.placeholders = data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    saveTemplate(status= null , preview=0){
      if(!this.template.subject /*|| !this.template.header_image*/ || !this.template.content||!this.template.policy){
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      const formData = new FormData();
      formData.append('id',this.template.id)
      formData.append('subject',this.template.subject)
      formData.append('header_image',this.template.header_image)
      formData.append('message',this.template.content)
      formData.append('policy',this.template.policy)
      formData.append('slug',this.template.slug)
      if(preview){
        formData.append('preview',1)
      }
      if(status){
        formData.append('status',status)
      }
      if (this.template.footer_images){
        this.template.footer_images.forEach((image, index) => {
          formData.append(`footer_images[${index}]`, image)
        })
      }
      if (this.template.deletedFooterImages){
        this.template.deletedFooterImages.forEach((image, index) => {
          formData.append(`deleted_footer_images[${index}]`, image)
        })
      }
      let url = `venues/marketing/template-preview/${this.template.slug}`;
      if(!preview){
        url = `venues/marketing/template/${this.template.slug}`;
      }
      const config = {
        method: "post",
        url: url,
        data: formData,
      };
      this.$http(config)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status == true) {
              let  responseData = response.data.data;
              if(responseData.id){
                this.template.id = responseData.id;
              }
              this.showSuccess("Template saved");
            }else if(response.status ===200 && preview){
              this.previewModal = true
              this.previewContent  = response.data;

            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },

    closePreviewModal(){
      this.previewModal =false
    },
    handleFormSubmit(){},


    getTemplateType(slug){
      let emailTemplates= [
        { slug: "order-confirmation-mail",type:'order_confirmation'},
        { slug: "order-cancellation-mail", type:"order_cancel"},
        { slug: "event-cancellation-mail", type:"event_cancel"},
        { slug: "welcome-mail" , type:"welcome_mail"},
        { slug: "member-activation-mail",type:"new_member_mail"},
        { slug: "renew-member-mail",type:"renew_member_mail"},
        { slug: "survey-mail",type:"survey_mail"},
        { slug: "e-invoice-mail",type:"e_invoice_mail"},
        { slug: "pending-booking-mail" ,type:"approval_mail"},
        { slug: "reminder-mail",type:"reminder_mail"},
        { slug: "order-reservation-mail", type: 'order_reservation'},
        { slug: "order-refund-mail", type: 'order_refund' },
        { slug: "membership-purchase-mail",type:'membership_purchase' },
        { slug: "promo-code-mail", type: 'promo_mail' },
        // { slug: "new-member-mail", name: "New Member Mail", icon: "@/assets/images/misc/mail_box.png" },
        { slug: "e-invoice-membership-mail",type: 'e_invoice_membership_mail'},
        { slug: "facility-booking-reschedule",type: 'order_reservation'},
        { slug: "auto-debit-failure",type: 'auto_debit_failure'},
      ]
      let type =  emailTemplates.find((template)=>{return template.slug===slug})
      if(type){
        return type.type;
      }
      return null;
    },
  }
}
</script>